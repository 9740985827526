


// Set the date we're counting down to
var countDownDate = new Date(Date.UTC(2021, 9, 30, 0, 0, 1));

// Update the count down every 1 second
var x = setInterval(function () {
    if (document.getElementById("days")) {
        // Get today's date and time
        var now = new Date().getTime();
        // Find the distance between now and the count down date
        var distance = countDownDate - now;
        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        document.getElementById("days").innerHTML = days + "<sub> Days </sub>";
        document.getElementById("hours").innerHTML = hours + "<sub> Hours </sub>";
        document.getElementById("minutes").innerHTML = minutes + "<sub> Minutes </sub>";
        document.getElementById("seconds").innerHTML = seconds + "<sub> Seconds </sub>";
        // If the count down is finished, write some text 
        if (distance < 0) {
            clearInterval(x);
            document.getElementById("count").innerHTML = "EXPIRED";
        }
    }
}, 1000);



const MintCountdown = ({ MmDetected = true, mintFeedback }) => {
    return (
        <div id="mint">
            <h2>Minting Launch</h2>

            Checking mint availability<br /> <br />
            {/*             
            <div id="count"></div>
            <div className="time">
                <div id="days" className="days">
                </div>
                <div id="hours" className="hours">
                </div>
                <div id="minutes" className="minutes">
                </div>
                <div id="seconds" className="seconds">
                </div>
            </div> */}

            {!MmDetected ? <div>You will need MetaMask installed to mint! <a href="https://metamask.app.link/dapp/phantomgang.xyz">Get MetaMask!</a></div> : ""}
            {mintFeedback}

            <div className="howtomint">
                <h2>How To mint</h2>
                <p>Minting on Polygon is straight forward, although if it’s your first time there’s a couple of things to do! If you have any questions at all, please join our <a href="https://discord.gg/bztWbpqVEy">Discord.</a>
                    <br /><br />
                    Add matic mainnet by clicking <a href="https://chainlist.org/">here</a> and searching for matic then click Add to metamask.Send enough Ether over the <a href="https://bridge.umbria.network/bridge/">Umbria Bridge</a> to cover your Phantom Gang purchase each mint will cost .0666 ETH. Sending ETH over this bridge wraps it, converting it WETH. This step takes about 2 minutes, and you will incur a small gas fee – the bridge will tell you what you’ll receive on polygon at the bottom.  You’ll need to switch back to Ethereum Mainnet in your MetaMask for this step to work.
                    <br /><br />
                    -After the transaction has completed, you can visit <a href="https://wallet.polygon.technology/">The Matic Wallet</a> to verify that your WETH has arrived. Click the three dots to the right to “Add to MetaMask”. Your WETH should now show in your MetaMask wallet when Matic Mainnet is selected. (If, for some reason this doesn’t work, instead go back to MetaMask, get on your Matic Mainnet page, scroll down to “Add Token”, and add the address 0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619).
                    <br /><br />
                    You're ready to hit mint and get your Phantoms!
                </p>
            </div>

            <div className="bottom-wave"></div>
        </div>
    )
}

export default MintCountdown;