const Faq = () => {
    return (


        <div id="faq">
            <img src="images/faq.png" />
            <h2>FAQ</h2>
            <p>
                <b>Where do I join the community?</b><br />
                We’re on Discord and Twitter<br /><br />
                <b>What is the launch date for Phantom Gang?</b><br />
                Phantom Minting will be available from 00:01 UTC, on October 30th, 2021.<br /><br />
                <b>Are there going to be a lot of Phantoms available?</b><br />
                The maximum available number of Phantoms is 6,666.
                <br /><br />
                <b>What are Phantom Swamp Plot NFTs?</b><br />
                The Phantom Warz Whitepaper will explain these in detail.
                <br /><br />
                <b>How many Phantoms can I mint per transaction?</b><br />
                A maximum of 13 Phantoms can be minted per transaction.<br /><br />
                <b>What is the minting cost for one Phantom?</b><br />
                0.0666 ETH <br /><br />
                <b>After the initial release, how long do I wait until my Phantoms are revealed?</b><br />
                Once you mint your Phantoms they will be immediately available on OpenSea. <br /><br />
                <b>What benefit do I get from owning Phantom Gang NFTs?</b><br />
                The roadmap shows some of our plans for bringing utility to Phantom Gang NFTs. We will update our roadmap again in Q1 2022. The primary current focus is to bring further utility through the Phantom Warz game.<br /><br />
                <b>Can you remind me about anything to do with the project?</b><br />
                Sure! The best ways are to join our communities on Twitter and Discord. <br /><br />         </p>
        </div>


    )
}

export default Faq