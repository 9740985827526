import { useEffect, useState } from "react"
import Web3 from "web3";
import config from "../config"
import erc20config from "../erc20config"



const MintModal = ({ mintQty, setMintQty, currentAllowance, price, balance, erc20Contract, selectedAddress, walletWeb3, web3, getCurrentAllowance, contract }) => {
    const [showModal, setShowModal] = useState(false);
    const [isWorking, setIsWorking] = useState(false);
    const [userFeedback, setUserFeedback] = useState("")


    useEffect(() => {
        console.log(price)
        console.log(currentAllowance)
        console.log(balance)
    }, [])

    useEffect(() => {
        if (userFeedback === "WETH Spend Approved!") {
            setTimeout(() => {
                setUserFeedback('')
            }, 1500);
        }
    }, [userFeedback])

    const handleMintQty = (change) => {
        let newVal = mintQty + change;
        if(newVal === 0 || newVal === 14){
            // do fuck all
        }
        else{
            setMintQty(newVal)
        }
    }

    const onSubmitSetAllowance = async () => {
        if (mintQty > 0 && erc20Contract) {
            setIsWorking(true)
            console.log("user:" + selectedAddress)
            let userAddress = selectedAddress;
            let nonce = await erc20Contract.methods.getNonce(userAddress).call();
            let functionSignature = erc20Contract.methods.approve(config.contract.address, (price * mintQty).toString()).encodeABI();
            let message = {};
            message.nonce = parseInt(nonce);
            message.from = userAddress;
            message.functionSignature = functionSignature;

            console.log("*****************************")

            console.log(config.domainType)
            console.log(config.metaTransactionType)
            console.log(erc20config.erc20domainData)
            console.log(message)
            console.log(userAddress)

            console.log("*****************************")

            const dataToSign = JSON.stringify({
                types: {
                    EIP712Domain: config.domainType,
                    MetaTransaction: config.metaTransactionType
                },
                domain: erc20config.erc20domainData,
                primaryType: "MetaTransaction",
                message: message
            });

            // NOTE: Using walletWeb3 here, as it is connected to the wallet where user account is present.
            walletWeb3.currentProvider.send(
                {
                    jsonrpc: "2.0",
                    id: 999999999999,
                    method: "eth_signTypedData_v4",
                    params: [userAddress, dataToSign]
                },
                function (error, response) {
                    console.info(`User signature is ${response.result}`);
                    if (error || (response && response.error)) {
                        setUserFeedback("Could not get user signature");
                        setIsWorking(false)
                    } else if (response && response.result) {
                        let { r, s, v } = getSignatureParameters(response.result);
                        sendErc20Transaction(userAddress, functionSignature, r, s, v);
                    }
                }
            );
        } else {
            console.log("Please select number of tokens to mint");
        }

    };

    const sendErc20Transaction = async (userAddress, functionData, r, s, v) => {

        console.log(userAddress)
        console.log(functionData)
        console.log(r)
        console.log(s)
        console.log(v)


        if (web3 && erc20Contract) {
            try {
                console.log("getting gas estimate.....")
                // let gasLimit = await erc20Contract.methods
                //     .executeMetaTransaction(userAddress, functionData, r, s, v)
                //     .estimateGas({ from: userAddress });
                let gasPrice = await web3.eth.getGasPrice();
                // console.log("gas limit: " + gasLimit);
                console.log("gas price: " + gasPrice);
                let tx = erc20Contract.methods
                    .executeMetaTransaction(userAddress, functionData, r, s, v)
                    .send({
                        from: userAddress,
                        gasPrice: web3.utils.toHex(gasPrice),
                        gasLimit: web3.utils.toHex(285000)
                    });
                tx.on("transactionHash", function (hash) {
                    console.log(`Transaction hash is ${hash}`);
                    setUserFeedback(`Requesting WETH spend approval - hash: ${hash}`);
                }).once("confirmation", function (confirmationNumber, receipt) {
                    console.log(receipt);
                    setUserFeedback("WETH Spend Approved!");
                    getCurrentAllowance(userAddress);
                    setIsWorking(false)
                });
            } catch (error) {
                console.log(error);
                setUserFeedback("Transaction Failed :(  Please try reloading the page");
                setIsWorking(false)
            }
        }
    };



    const getSignatureParameters = (signature) => {
        if (!web3.utils.isHexStrict(signature)) {
            throw new Error(
                'Given value "'.concat(signature, '" is not a valid hex string.')
            );
        }
        var r = signature.slice(0, 66);
        var s = "0x".concat(signature.slice(66, 130));
        var v = "0x".concat(signature.slice(130, 132));
        v = web3.utils.hexToNumber(v);
        if (![27, 28].includes(v)) v += 27;
        return {
            r: r,
            s: s,
            v: v,
        };
    };


    
    const doMint = async event => {
        if (mintQty > 0 && contract) {
            setIsWorking(true)
            let userAddress = selectedAddress;
            let nonce = await contract.methods.getNonce(userAddress).call();
            let functionSignature = contract.methods.mintToken(mintQty).encodeABI();
            let message = {};
            message.nonce = parseInt(nonce);
            message.from = userAddress;
            message.functionSignature = functionSignature;

            const dataToSign = JSON.stringify({
                types: {
                    EIP712Domain: config.domainType,
                    MetaTransaction: config.metaTransactionType
                },
                domain: config.domainData,
                primaryType: "MetaTransaction",
                message: message
            });

            // NOTE: Using walletWeb3 here, as it is connected to the wallet where user account is present.
            walletWeb3.currentProvider.send(
                {
                    jsonrpc: "2.0",
                    id: 999999999999,
                    method: "eth_signTypedData_v4",
                    params: [userAddress, dataToSign]
                },
                function (error, response) {
                    console.info(`User signature is ${response.result}`);
                    if (error || (response && response.error)) {
                        setUserFeedback("Approval Cancelled");
                        setIsWorking(false)
                    } else if (response && response.result) {
                        let { r, s, v } = getSignatureParameters(response.result);
                        sendTransaction(userAddress, functionSignature, r, s, v);
                    }
                }
            );
        } else {
            setUserFeedback("Please try reloading the page");
        }
    };

    const sendTransaction = async (userAddress, functionData, r, s, v) => {
        if (web3 && contract) {
            try {
                console.log("getting gas estimate.....")
                let gasLimit = await contract.methods
                    .executeMetaTransaction(userAddress, functionData, r, s, v)
                    .estimateGas({ from: userAddress });
                let gasPrice = await web3.eth.getGasPrice();
                console.log("gas limit: " + gasLimit);
                console.log("gas price: " + gasPrice);
                let tx = contract.methods
                    .executeMetaTransaction(userAddress, functionData, r, s, v)
                    .send({
                        from: userAddress,
                        gasPrice: web3.utils.toHex(gasPrice),
                        // gasLimit: web3.utils.toHex(gasLimit)
                    });

                tx.on("transactionHash", function (hash) {
                    console.log(`Transaction hash is ${hash}`);
                    setUserFeedback(`Mint in progress - hash ${hash}`);
                }).once("confirmation", function (confirmationNumber, receipt) {
                    console.log(receipt);
                    setUserFeedback("Mint Complete!  Check OpenSea to see your new Phantoms!");
                    setIsWorking(false)
                });
            } catch (error) {
                console.log(error);
                setUserFeedback("Transaction Failed");
                setIsWorking(false)
            }
        }
    };

    return (


        <div id="mint">
            <div class="mint-cta">
                <h1>Mint Now Live</h1>
                <button id="button-cta" onClick={() => { setShowModal(true) }}>Mint</button>
            </div>
            
            <div className="howtomint">
                <h2>How To mint</h2>
                <p>Minting on Polygon is straight forward, although if it’s your first time there’s a couple of things to do! If you have any questions at all, please join our <a href="https://discord.gg/bztWbpqVEy">Discord.</a>
                    <br /><br />
                    Add matic mainnet by clicking <a href="https://chainlist.org/">here</a> and searching for matic then click Add to metamask.Send enough Ether over the <a href="https://bridge.umbria.network/bridge/">Umbria Bridge</a> to cover your Phantom Gang purchase each mint will cost .0666 ETH. Sending ETH over this bridge wraps it, converting it WETH. This step takes about 2 minutes, and you will incur a small gas fee – the bridge will tell you what you’ll receive on polygon at the bottom.  You’ll need to switch back to Ethereum Mainnet in your MetaMask for this step to work.
                    <br /><br />
                    -After the transaction has completed, you can visit <a href="https://wallet.polygon.technology/">The Matic Wallet</a> to verify that your WETH has arrived. Click the three dots to the right to “Add to MetaMask”. Your WETH should now show in your MetaMask wallet when Matic Mainnet is selected. (If, for some reason this doesn’t work, instead go back to MetaMask, get on your Matic Mainnet page, scroll down to “Add Token”, and add the address 0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619).
                    <br /><br />
                    You're ready to hit mint and get your Phantoms!
                </p>
            </div>


            <div className="bottom-wave"></div>
            {showModal ?
                <div id="myModal" class="modal">

                    <div class="modal-content">
                        <div class="modal-header">
                            <span class="close" onClick={() => { setShowModal(false) }}>&times;</span>
                            <h2>Mint...</h2>
                        </div>
                        <div class="modal-body">

                            <img style={{ maxWidth: "100%" }} class="gif" src="/images/phantoms.gif" />

                            <p>Mint your Phantom Gang pack (contains 2 Phantom Gang NFTs!) <strong>0.0666 ETH on Polygon</strong> </p>


                            <p class="modalcont">
                                {userFeedback.length > 0 ? userFeedback
                                    :
                                    <>

                                        <div class="input-group">
                                            <input type="button" value="-" class="button-minus" onClick={() => { handleMintQty(-1) }} />
                                            <input type="number" step="1" max="20" value="1" value={mintQty} class="quantity-field" disabled />
                                            <input type="button" value="+" class="button-plus" onClick={() => { handleMintQty(1) }} />
                                        </div>

                                        {(mintQty * 0.0666) > Web3.utils.fromWei(currentAllowance.toString(), 'ether') ?
                                            <button id="button" class="mint" onClick={onSubmitSetAllowance} disabled={price * mintQty > balance || isWorking} >Authorize Spend</button>
                                            :
                                            <button id="button" class="mint" onClick={doMint} disabled={price * mintQty > balance || isWorking}>Mint!</button>
                                        }
                                    </>
                                }
                            </p>


                            <p>Your total balance : {Web3.utils.fromWei(balance.toString(), 'ether')} Eth on Polygon</p>
                            <p>Total spend: {(mintQty * 0.0666).toFixed(4)} Eth on Polygon</p>

                            <p>
                                Phantom Gang Website currently is currently Authorized to spend <strong>{Web3.utils.fromWei(currentAllowance.toString(), 'ether')}</strong> Eth on Polygon
                            </p>

                        </div>
                    </div>
                </div>
                : <></>}
        </div>
    )
}

export default MintModal