import React, { useState } from 'react'

const Menu = () => {

    const [menuOpen, setMenuOpen] = useState(false)

    return (

        <div class="topnav">
            {menuOpen ?
                <div id="menu">
                    <a href="#mint" onClick={() => (setMenuOpen(!menuOpen))}>Mint</a>
                    <a href="#about" onClick={() => (setMenuOpen(!menuOpen))}>About</a>
                    <a href="#roadmap" onClick={() => (setMenuOpen(!menuOpen))}>Roadmap</a>
                    <a href="#faq" onClick={() => (setMenuOpen(!menuOpen))}>FAQ</a>
                    <a href="#team" onClick={() => (setMenuOpen(!menuOpen))}>Team</a>
                </div>
                : <></>}

            <a id="burger" class={`icon ${menuOpen ? `open` : ``}`} onClick={() => (setMenuOpen(!menuOpen))}>
                <span class="bar one"></span>
                <span class="bar two"></span>
                <span class="bar three"></span>
            </a>
        </div>

    )
}

export default Menu;