
const Info = () => {
    return (
        <>
            <div id="origins">
                <h2>origins</h2>
                <p>Hanging, dirty-green fog clouds sprawl across a post-apocalyptic, slime-ridden swamp, on the backside of the earth.
                    <br /><br />
                    The burning sky fires fade behind a crooked horizon, and the members of Phantom Gang emerge from the gloopy rot of previous millenia.
                    <br /><br />
                    These last mockeries of life now fight for dominance amid the muck of the Rancid Snotlands.
                </p>
            </div>
            <div id="about">
                <img src="images/Utility Icon.png" />
                <h2>Utility</h2>
                <p>Phantom Gang has six* completely different breeds of Phantom, each with 1111 unique Phantom variations.There is a spattering of super rare Phantoms across each breed.
                </p>
                <h2>Phantom<br />
                    Attributes</h2>
                <p>Each breed is weighted towards one of six base attributes.
                    <br /><br />
                    All Phantoms have a fixed number of 888 total points that are allocated across all attributes, with between 444 and 666 of the total points randomly allocated to the one core attribute for that breed.The rest of the points are then randomly allocated to the remaining five attributes for that breed.

                </p>

            </div>
            <div id="roadmap">
                <h2>Phantom Gang<br />
                    Characters</h2>

                <div class="phantoms">
                    <div class="phantom">
                        <img src="images/Pumpkinhead.png" />
                        <h2>PUMPKINHEAD</h2>
                    </div>
                    <div class="phantom">
                        <img src="images/Skulk.png" />
                        <h2>SKULK</h2>
                    </div>
                    <div class="phantom">
                        <img src="images/Frankie.png" />
                        <h2>FRANKIE</h2>
                    </div>
                    <div class="phantom">
                        <img src="images/Spidster.png" />
                        <h2>SPIDSTER</h2>
                    </div>
                    <div class="phantom">
                        <img src="images/WhichWitch.png" />
                        <h2>WHICHWITCH</h2>
                    </div>
                    <div class="phantom">
                        <img src="images/Goosty.png" />
                        <h2>GOOSTY</h2>
                    </div>
                    <div class="phantom">
                        <img src="images/Seventh.png" />
                        <h2>MYSTERY</h2>
                    </div>
                </div>
            </div>
            <div id="details">
                <div class="blackbox">
                    <h2>Roadmap</h2>
                    <img class="roadmapimg" src="images/roadmap.png" />
                </div>
                <div class="maroonbox">
                    <h2>Completed</h2>
                    <p>Three Seas Founded<br />
                        Initial Concept Artwork<br />
                        Summon Six Phantoms<br />
                        Launch Official Website<br />
                        Phantom Warz Draft Whitepaper<br />
                    </p>
                </div>
                <div class="octoberbox">
                    <h2>October</h2>
                    <p>Twitter Launch<br />
                        Discord Community Launch<br />
                        Minting Launch
                    </p>
                </div>
                <div class="novemberbox">
                    <h2>November</h2>
                    <p>Day of the Dead Hourly Rituals
                    </p>
                </div>
                <div class="decemberbox">
                    <h2>December</h2>
                    <p>Phantom Warz Whitepaper Published
                    </p>
                </div>
                <div class="twentytwobox">
                    <h2>2022</h2>
                    <p>TBA


                    </p>
                </div>
            </div>
        </>
    )
}

export default Info;