const Team = () => {
    return (
        <div id="team">
            <img src="images/team.png" />
            <h2>TEAM</h2>
            <p>Three Seas have a core team consisting of two UK-based developers and a designer. They have worked across a range of creative tech organisations for more than 15 years.
            </p>
            <img class="circle" src="images/mayc.png" />
            <h4><a href="https://twitter.com/coadydoteth">@CoadyDotEth</a></h4>
            <p>A veteran of web design, spending a number of years in web2 but now residing almost entirely in Web3. An NFT addict to the core.
            </p>
            <img class="circle" src="images/wicked.png" />
            <h4><a href="https://twitter.com/cgcw8">@cgcw8</a></h4>
            <p>A web2 come web3 dev looking forward to the next big thing.  Cross chain hybrid platforms in crosshairs.
            </p>
            <img class="circle" src="images/gevol.jpg" />
            <h2>Art</h2>
            <p>A lifelong fascination with graffiti art and video game culture. They worked in the web industry for over a decade as a creative lead. Art studied traditional methods in Italy, and contemporary painting in the UK. They have also worked on animation projects for games companies and TV ads.
            </p>
        </div>
    )
}

export default Team;