import React, { useEffect, useState } from 'react';
import './App.css';
import Faq from './components/faq';
import Footer from './components/footer';
import Hero from './components/hero';
import Info from './components/info';
import Menu from './components/menu';
import MintCountdown from './components/mintcountdown';
import Team from './components/team';
import Web3 from "web3";
import { Biconomy } from "@biconomy/mexa";
import config from "./config"
import erc20config from "./erc20config"
import MintModal from './components/mintmodal';


let web3, walletWeb3, biconomy;
let contract, walletContract;
let erc20Contract, walleterc20Contract;

function App() {
  const price = 66600000000000000; // 0.0666  
  const [saleIsPaused, setSaleIsPaused] = useState(true);
  const [tokensToMint, setTokensToMint] = useState(1);
  const [balance, setBalance] = useState(-1);
  const [totalCostDisplay, setTotalCostDisplay] = useState(Web3.utils.fromWei(price.toString(), 'ether'));
  const [isWorking, setIsWorking] = useState(false);
  const [mmDetected, setMmDetected] = useState(true);
  const [mintFeedback, setMintFeedback] = useState("")
  const [selectedAddress, setSelectedAddress] = useState("");
  const [currentAllowance, setCurrentAllowance] = useState(-1);
  const [tokensIssuedCount, setTokensIssuedCount] = useState();
  const [balanceDisplay, setBalanceDisplay] = useState(-1);

  useEffect(() => {
    async function init() {
      if (
        typeof window.ethereum !== "undefined" &&
        window.ethereum.isMetaMask
      ) {
        // Ethereum user detected. You can now use the provider.
        const provider = window["ethereum"];
        await provider.enable();
        biconomy = new Biconomy(window.ethereum, {
          walletProvider: window.ethereum,
          apiKey: config.apiKey,
          debug: false,
        });

        // This web3 instance is used to read normally and write to contract via meta transactions.
        web3 = new Web3(biconomy);

        // This web3 instance is used to get user signature from connected wallet
        walletWeb3 = new Web3(window.ethereum);

        //reload if mm changes acc or chain
        provider.on("accountsChanged", (_acct) => window.location.reload());
        provider.on('chainChanged', (_chainId) => window.location.reload());

        biconomy
          .onEvent(biconomy.READY, () => {
            // Initialize your dapp here like getting user accounts etc
            console.log(biconomy.networkId)
            if (biconomy.networkId !== config.requiredChainId.toString()) {
              setMintFeedback("Please change network to Matic Mainnet")
            }
            else {
              contract = new web3.eth.Contract(
                config.contract.abi,
                config.contract.address
              );
              walletContract = new walletWeb3.eth.Contract(
                config.contract.abi,
                config.contract.address
              );
              erc20Contract = new web3.eth.Contract(
                erc20config.contract.abi,
                erc20config.contract.address
              );
              walleterc20Contract = new walletWeb3.eth.Contract(
                erc20config.contract.abi,
                erc20config.contract.address
              );
              setSelectedAddress(provider.selectedAddress);
              getCurrentAllowance(provider.selectedAddress);
              getBalance(provider.selectedAddress);
              getTokensIssuedCount();
              getSaleIsPaused();
            }
          })
          .onEvent(biconomy.ERROR, (error, message) => {
            // Handle error while initializing mexa
            if(error.message === "Could not get network version"){
              window.location.reload();
            }
            console.log(error)
            console.log(message)
            console.log("Biconomy Failed")
            setMintFeedback("Please check you're connected to Matic Mainnet ")
          });
      } else {
        setMmDetected(false)
      }
    }
    init();
  }, []);




  const getCurrentAllowance = async (userAddress) => {
    console.log("Getting allowance from contact ...");
    try {
      let resp = await walleterc20Contract.methods.allowance(userAddress, config.contract.address).call();
      console.log(resp);
      setCurrentAllowance(resp)
    } catch (error) {
      console.log(error);
    }
  };
  const getBalance = async (userAddress) => {
    try {
      let resp = await walleterc20Contract.methods.balanceOf(userAddress).call();
      console.log(resp)
      const etherValue = Web3.utils.fromWei(resp, 'ether');
      setBalance(resp)
      setBalanceDisplay(etherValue)

    } catch (error) {

      console.log(error);
    }
  };
  const getTokensIssuedCount = async () => {
    try {
      let resp = await walletContract.methods.totalSupply().call();
      setTokensIssuedCount(resp)

    } catch (error) {
      console.log(error);
    }
  };
  const getSaleIsPaused = async () => {
    try {
      let resp = await walletContract.methods.salePaused().call();
      setSaleIsPaused(resp)

    } catch (error) {
      console.log(error);
    }
  };



  return (

    <div id="container">
      <Menu></Menu>
      <Hero></Hero>

      {saleIsPaused ?
        <MintCountdown MmDetected={mmDetected} mintFeedback={mintFeedback}></MintCountdown>
        : <MintModal
          mintQty={tokensToMint}
          setMintQty={setTokensToMint}
          currentAllowance={parseInt(currentAllowance)}
          price={price}
          balance={parseInt(balance)}
          erc20Contract = {erc20Contract}
          selectedAddress = {selectedAddress}
          walletWeb3 = {walletWeb3}
          web3 = {web3}
          getCurrentAllowance = {getCurrentAllowance}
          contract = {contract}
        ></MintModal>
      }

      <Info></Info>
      <Team></Team>
      <Faq></Faq>
      <Footer></Footer>
    </div>

  );

}

export default App;
