const Footer = () => {
    return (
        <div id="footer">
            <div class="footerholder">
                <a href="https://twitter.com/PhantomGangNFT"><img src="images/Twitter.png" /></a>
                <a href="https://discord.gg/bztWbpqVEy"><img src="images/Discord.png" /></a>
            </div>
            <p>Three Seas © 2021<br />
                All Rights Reserved</p>
        </div>
    )
}

export default Footer