
const Hero = () => {
    return (
        <div className="hero" id="hero">
            <img className="heroimg" src="images/logo.png" />
            <div className="holder">
                <a href="https://twitter.com/PhantomGangNFT" className="leftimg"><img src="images/Twitter.png" ></img></a>
                <a href="https://discord.gg/bztWbpqVEy" className="rightimg"><img src="images/Discord.png" /></a>
            </div>
            <img src="images/Gravestone.png" className="gravestone" />
        </div>
    )
}

export default Hero;